<template>
  <div class="formation-portal">
    <div class="formation-portal__wrapper"
         :style="{backgroundImage: backgroundImage !== '' ? `url(${backgroundImage})` : '',
                  backgroundPosition}">
      <Column justify="center">
        <Column class="formation-portal__meta">
          <slot name="formation-portal-sup-title"/>
          <slot name="formation-portal-title"/>
          <slot name="formation-portal-description"/>
        </Column>
        <div class="formation-portal__slot">
          <slot/>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormationPortal',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    backgroundImage: {
      type: String,
    },
    backgroundPosition: {
      type: String,
    },
  },
};
</script>
